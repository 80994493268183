<template>
  <div class="app-content content" :class="[{'show-overlay': $store.state.app.shallShowOverlay}]">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-body">
        <transition name="zoom-fade" mode="out-in">
          <div class="card">
            <div class="m-2">
              <div class="row">
                <div class="col-12 mb-2">
                  <div class="d-flex align-items-center justify-content-end">
                    <b-form-input
                        v-model="search"
                        @change="getInventory" class="d-inline-block mr-1"
                        placeholder="Поиск..."
                    />
                  </div>
                </div>
                <div class="col-12">
                  Всего на складе {{ inventory.total }} штук
                </div>
              </div>
            </div>
            <app-collapse accordion>
              <app-collapse-item
                  v-for="(storage, index) in inventory.batches"
                  :key="index"
                  :title="`Коробка: ${storage[0].storage_index}`"
              >
                <div class="list-group">
                  <div v-for="(item, subIndex) in storage" :key="subIndex" class="list-group-item">
                    <div class="row">
                      <div class="col">
                        {{ item.name }} {{ item.brand_name }} ({{ item.sku }}) {{ item.condition_name }} размер {{ item.size }}
                      </div>
                      <div class="col-auto">
                        Кол-во: {{ item.qty }}
                      </div>
                    </div>
                  </div>
                </div>
              </app-collapse-item>
            </app-collapse>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue';
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue';
import { BFormInput } from 'bootstrap-vue';

export default {
  name: 'Inventory',
  components: {
    AppCollapse, AppCollapseItem, BFormInput,
  },
  data() {
    return {
      inventory: {
        batches: [],
        total: 0,
      },
      search: null,
    };
  },
  async mounted() {
    await this.getInventory();
  },
  methods: {
    async getInventory() {
      this.inventory = (await this.$api.reports.inventory({
        ...(this.search && { search: this.search }),
      })).data;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';

.per-page-selector {
  width: 90px;
}

span.amount {
  font-size: 18px;
}
.positive {
  color: $success;
}
.negative {
  color: $danger;
}
span.currency {
  font-size: 10px;
}

.discount {
  text-transform: uppercase;
  &.warning {
    color: $warning;
    text-transform: uppercase;
  }
  &.danger {
    color: $danger;
  }
}
</style>
